import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_PromoCodesDetailsTable = _resolveComponent("PromoCodesDetailsTable")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createTextVNode(" Promo codes "),
      _createVNode(_component_va_button, {
        class: "ml-4",
        onClick: _ctx.goToPromoCodeCreator,
        color: "success"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create new promo code")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.promoCodes)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.promoCodes
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.promoCodes))
        ? (_openBlock(), _createBlock(_component_PromoCodesDetailsTable, {
            key: 0,
            "promo-codes": _ctx.data.promoCodes.data,
            onRequestUpdate: _ctx.loadPromoCodes
          }, null, 8, ["promo-codes", "onRequestUpdate"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}