import axios from 'axios'
import { getAuthHeaders, getRequestIdHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import { apiErrorCatcher } from '@/api/_shared'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { PromoCode } from '@/modules/slowpoke/model/promo-codes.model'

export async function getAllPromoCodes(): Promise<PromoCode[]> {
  const res = await simpleAuthorizedQuery<PromoCode[]>(slowpokeApiUrl('admin/promo-codes'))

  return (res || []).map((v) => new PromoCode(v))
}

export async function addPromoCode(balanceDelta: number, description: string): Promise<PromoCode> {
  return axios
    .post(
      slowpokeApiUrl(`admin/promo-codes`),
      {
        balanceDelta,
        description,
      },
      {
        headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
      }
    )
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new PromoCode(data))
}

export async function deletePromoCode(id: number): Promise<unknown> {
  return axios
    .delete(slowpokeApiUrl(`admin/promo-codes/${id}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
}
