export class PromoCode {
  id?: number
  clientID?: number
  code: string
  description: string
  balanceDelta: number
  usedAt: Date | null
  createdAt: Date

  constructor(doc: Partial<PromoCode>) {
    this.id = doc.id
    this.clientID = doc.clientID
    this.code = doc.code || ''
    this.description = doc.description || ''
    this.balanceDelta = doc.balanceDelta || 0
    this.usedAt = doc.usedAt ? new Date(doc.usedAt) : null
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
  }
}
