
import { defineComponent, PropType } from 'vue'
import { PromoCode } from '@/modules/slowpoke/model/promo-codes.model'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { deletePromoCode } from '@/modules/slowpoke/api/promo-codes.api'
import { useToaster } from '@/composables/useToaster'

export default defineComponent({
  name: 'SlowpokePromoCodesDetailsTable',
  computed: {
    SlowpokeRoute() {
      return SlowpokeRoute
    },
  },
  props: {
    promoCodes: {
      type: Array as PropType<PromoCode[]>,
      required: true,
    },
  },
  emits: ['requestUpdate'],
  setup(props, { emit }) {
    const toaster = useToaster()

    const deletePromoCodeHandler = async (id: number) => {
      try {
        await deletePromoCode(id)
        toaster && toaster.success('Promo code has been successfully deleted.')
        emit('requestUpdate')
      } catch (e) {
        toaster && toaster.success('Could not delete promo code.')
      }
    }

    return {
      deletePromoCodeHandler,
    }
  },
})
