
import { defineComponent } from 'vue'
import { isLoaded, isErrored, isLoading } from '@/utils/loading-state'
import Panel from '@/components/UI/Panel.vue'
import GoBack from '@/components/UI/GoBack.vue'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import PromoCodeEditor from '@/modules/slowpoke/components/promo-codes/PromoCodeEditor.vue'

export default defineComponent({
  name: 'SlowpokePromoCode',
  components: { PromoCodeEditor, GoBack, Panel },
  setup() {
    return {
      isLoaded,
      isLoading,
      isErrored,
      SlowpokeRoute,
    }
  },
})
