
import { defineComponent, reactive } from 'vue'
import { isLoaded, LOADING_STATE, LoadingState, isErrored, isLoading, loaded, errored } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import Panel from '@/components/UI/Panel.vue'
import { PromoCode } from '@/modules/slowpoke/model/promo-codes.model'
import { useRouter } from 'vue-router'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import PromoCodesDetailsTable from '@/modules/slowpoke/components/promo-codes/PromoCodesDetailsTable.vue'
import { getAllPromoCodes } from '@/modules/slowpoke/api/promo-codes.api'

export default defineComponent({
  name: 'SlowpokeVoicePicker',
  components: { PromoCodesDetailsTable, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      promoCodes: LOADING_STATE as LoadingState<PromoCode[]>,
    })

    const router = useRouter()

    const goToPromoCodeCreator = () => {
      router.push({ name: SlowpokeRoute.AddPromoCode })
    }

    const loadPromoCodes = async () => {
      try {
        data.promoCodes = LOADING_STATE
        data.promoCodes = loaded(await getAllPromoCodes())
      } catch (e) {
        data.promoCodes = errored(e)
      }
    }

    loadPromoCodes()

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      goToPromoCodeCreator,
      loadPromoCodes,
    }
  },
})
