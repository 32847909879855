
import { defineComponent, reactive } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'
import { addPromoCode } from '@/modules/slowpoke/api/promo-codes.api'

export default defineComponent({
  name: 'SlowpokePromoCodeEditor',
  components: {
    FormStateAlert,
  },
  setup() {
    const data = reactive({
      balanceDelta: 0,
      description: '',
      formState: FORM_INITIAL_STATE as FormState,
    })

    const router = useRouter()
    const toaster = useToaster()

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const addPromoCodeHandler = async () => {
      try {
        await addPromoCode(Number(data.balanceDelta), data.description)
        data.formState = formSuccess(`Promo code has been successfully created.`)
        await router.push({ name: SlowpokeRoute.PromoCodes })
      } catch (e) {
        data.formState = formError(`Promo code could not be created. ${e}`, (e as ApiError)?.errors)
      }
    }

    return {
      data,
      actionHandler,
      addPromoCodeHandler,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
    }
  },
})
