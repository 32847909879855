import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_form = _resolveComponent("va-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Number of seconds to grant",
            modelValue: _ctx.data.balanceDelta,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.balanceDelta) = $event)),
            type: "number"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Description",
            modelValue: _ctx.data.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.description) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_button, {
            class: "mr-2",
            onClick: _cache[2] || (_cache[2] = () => _ctx.actionHandler(_ctx.addPromoCodeHandler)),
            disabled: _ctx.isFormLoading(_ctx.data.formState),
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ])
  ], 64))
}